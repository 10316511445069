<template>
  <div class="EduHome">
    <PurchasePageHeader />

    <main>
      <div class="page1-container" style="background: #f4f4f4">
        <div class="w1200">
          <div class="rlineHeight">
            <span @click="$router.push({ path: '/PurchasePage' })">首页</span> /
            <span @click="$router.push({ path: '/purchaseMore' })"
              >课程列表</span
            >
            / {{ detailFrom.name }}
          </div>
          <div class="PurchasePageShow_InfoMain">
            <div class="PurchasePageShow_InfoMain_left">
              <img v-if="detailFrom.image" :src="detailFrom.image" />
              <img v-else src="../../assets/PurchasePage/02.png" />
            </div>
            <div class="PurchasePageShow_InfoMain_right">
              <div
                class="PurchasePageShow_InfoMain_right_title"
                :title="detailFrom.name"
              >
                {{ detailFrom.name }}
              </div>
              <div
                class="PurchasePageShow_InfoMain_right_font"
                :title="detailFrom.summary"
              >
                {{ detailFrom.summary }}
              </div>
              <div class="PurchasePageShow_InfoMain_money">
                {{ (detailFrom.price / 100).toFixed(2) }} 元
              </div>
              <div
                class="PurchasePageShow_InfoMain_button"
                @click="gotoShopping"
              >
                购买课程
              </div>
              <div class="purcchaseAxpiresAt">
                学习有效期至：{{ detailFrom.expiresAt }}
              </div>
            </div>
          </div>
          <div class="PurchasePage_Ninfo">
            <div class="PurchasePage_Ninfo_left">
              <div class="richText" v-html="detailFrom.description"></div>
            </div>
            <div class="PurchasePage_Ninfo_right">
              <div
                class="PurchasePage_Ninfo_right_img"
                @click="qrcodeShow = true"
              >
                <img src="../../assets/PurchasePage/01.png" />
              </div>
              <div class="PurchasePage_Ninfo_ul">
                <div class="PurchasePage_Ninfo_ul_title">
                  <div class="fl">相关课程推荐</div>
                  <div
                    class="fr"
                    @click="$router.push({ path: '/purchaseMore' })"
                  >
                    更多》
                  </div>
                </div>
                <ul>
                  <li v-for="item in productList" :key="item.id">
                    <a href="#" @click="handleProduct(item)">
                      <div class="flimg">
                        <img v-if="item.image" :src="item.image" />
                        <img v-else src="../../assets/PurchasePage/03.png" />
                      </div>
                      <div class="flimg-row">
                        <div class="flimg-row-title" :title="item.name">
                          {{ item.name }}
                        </div>
                        <div class="flimg-row-font" :title="item.summary">
                          {{ item.summary }}
                        </div>
                        <!-- 随机数 -->
                        <!-- <div class="flimg-row-int">{{ Math.floor(Math.random() * 10 + 10) }}人在学习</div> -->
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- 购买 -->
    <div v-if="isPay" class="NewPayShow">
      <div class="NewTopicInfo">
        <div class="close" @click="showClosePayTip()"></div>
        <div class="infoTitle">
          <!-- <div class="title">选择适合您的VIP计划</div>
          <div class="title-font">购买多多，优惠多多</div> -->
          <div class="title-font">
            订单已提交，请在48小时内完成支付！逾期订单将被取消。
            <!-- 注意:如已扫描支付，请勿重复扫描支付，若长时间未返回订购成功，可去购买记录查看结果! -->
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 20px;
          "
        >
          <img src="~@/assets/outline/weipaylogo.png" />
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          "
        >
          <div class="warBr">
            <div v-if="scanningCodeUrl" class="w-logo">
              <img src="~@/assets/logo.png" />
            </div>
            <img v-if="scanningCodeUrl" :src="scanningCodeUrl" />
            <span v-else>正在创建订单...</span>
            <!-- <img  src="~@/assets/code.png" /> -->
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 20px;
            font-size: 16px;
          "
        >
          应付金额：<span
            style="color: #fd921d; font-weight: bold; font-size: 18px"
            >￥{{ detailFrom.price / 100 }}</span
          >
        </div>
        <div style="text-align: center; margin-top: 50px">
          <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
          <span style="cursor: pointer; color: #173d7c" @click="privacyPolicy"
            >《隐私协议》</span
          >
        </div>
      </div>
    </div>

    <!-- 购买成功 -->
    <div class="showFile" v-if="fileShow">
      <div class="showFile-info">
        <i @click="fileShow = false"><img src="~@/assets/icon-gb.png" /></i>
        <div class="left-msg">
          <img src="~@/assets/message.png" />
          <span>&nbsp;&nbsp;系统通知&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <!-- <span>2022-10-27 21:41:41</span> -->
        </div>
        <div class="showFil-conent">
          <div class="title">课程购买成功!</div>
          <div class="title-text">恭喜您成功购买【{{ detailFrom.name }}】!</div>
          <div class="title2">学习方式:</div>
          <div class="info-font">
            <p>（1）点击右上角【教学平台】进入华路沉浸式教学互动平台</p>
            <p>
              （2）在首页【我的仪表盘】中，点击标签页【我的课程】，选择您的课程后，即可开始学习。
            </p>
          </div>
        </div>

        <div class="showFile-info-button" @click="toStudy">开始学习</div>
        <!-- <div class="showFile-info-h">
          <span @click="toBrowse">继续浏览课程</span>
        </div> -->
      </div>
    </div>

    <div class="showFile" v-if="qrcodeShow">
      <div class="showFile-info">
        <i @click="qrcodeShow = false"><img src="~@/assets/icon-gb.png" /></i>
        <div class="kefu-cont">
          <div class="kefu-title">咨询课程快速预约通道</div>
          <div class="qrcode">
            <img src="../../assets/PurchasePage/kefu.png" />
          </div>
          <div class="wechat">
            <img src="../../assets/weixin.png" />
          </div>
          <div class="wechat-text">扫码添加专属客服，立即了解更多课程资讯!</div>
        </div>
      </div>
    </div>

    <footer class="purchaseiFooter">
      <div class="purchaseiFooter-top">
        <div class="w1200">
          <div class="footer_left">
            <div><img src="../../assets/footer_logo.png" /></div>
            <div class="footer_left_font">
              华路数字文化<br />CPA沉浸式教学互动平台
            </div>
          </div>
          <div class="footer_right">
            <div class="fl_div">
              <div class="fl_dev_title">快捷服务</div>
              <div class="fl_dev_font">
                <p>
                  <a href="http://www.cufehl.com" target="_balnk">关于我们</a>
                </p>
                <p @click="$router.push({ path: '/purchaseitemInfo' })">
                  <a href="#">联系我们</a>
                </p>
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">微信公众号</div>
              <div class="mt20">
                <img src="../../assets/footer_code.png" />
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">客服/咨询</div>
              <div
                class="mt30"
                @click="$router.push({ path: '/purchaseitemInfo' })"
              >
                <img src="../../assets/title_1.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purchaseiFooter-bottom">
        <div class="w1200">
          <div class="fl">COPYRIGHT © 2009-2020 华路数字文化 版权所有</div>
          <div class="fr">粤ICP备14094585号</div>
        </div>
      </div>
    </footer>

    <protocol-dialog ref="protocolDialog"></protocol-dialog>
  </div>
</template>

<script>
import PurchasePageHeader from "@/components/PurchasePageHeader";
import { getProductBouquById, getProductBouque } from "@/api/product.js";
import {
  createOrder,
  submitOrder,
  getOrderDetail,
} from "@/api/orderController";
import ProtocolDialog from "@/views/Login/components/ProtocolDialog.vue";
export default {
  name: "Detail",
  data() {
    return {
      checked: true,
      qrcodeShow: false,
      fileShow: false,
      isPay: false,
      scanningCodeUrl: "",
      detailFrom: {
        name: "",
        price: "",
        expiresAt: "",
        summary: "",
        image: "",
        id: "",
      },
      courseId: "",
      productList: [],
      courseName: "",
    };
  },
  components: {
    PurchasePageHeader,
    ProtocolDialog,
  },
  created() {
    this.courseId = this.$route.query.id;
    this.getCourseTree(this.courseId);
  },
  methods: {
    privacyPolicy() {
      this.$refs["protocolDialog"].showEdit("course");
    },

    toBrowse() {
      this.$router.push({
        path: "/purchaseMore",
      });
    },

    toStudy() {
      // 跳转学习页面
      this.$router.push({
        path: "/home",
        query: {
          activeName: "myCourse",
        },
      });
    },
    showClosePayTip() {
      this.$confirm("您是否确认放弃本次支付?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.closePay();
        })
        .catch(() => {});
    },
    // 关闭
    closePay() {
      this.isPay = false;
      window.clearTimeout(window.orderStatusTimer);
    },
    // 购买课程
    gotoShopping() {
      // 判断当前是否登录
      console.log("未登录?", this.$store.state.isLogout);
      if (this.$store.state.isLogout) {
        // 未登录, 跳转登录页面
        this.$router.push({
          path: "/Login",
          query: { redirect: this.$route.path + "?id=" + this.courseId },
          replace: true,
        });
      } else {
        this.pay();
      }
    },

    pay() {
      let exam = this.detailFrom;

      this.isPay = true;
      let product = { items: [{}] };
      product.subject = exam.name;
      product.items[0].kind = "BOUQUET"; //  待确认
      product.items[0].refId = exam.id;
      product.items[0].name = exam.name;
      product.items[0].count = 1;
      this.scanningCodeUrl = "";
      createOrder(product).then((res) => {
        if (res.code == 20000) {
          this.submitOrder(res.data);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    submitOrder(data) {
      submitOrder(data).then((res) => {
        if (res.code == 20000) {
          this.scanningCodeUrl = res.data.qrImage;
          this.getOrderDetail(res.data.order.serialNum);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getOrderDetail(serialNum) {
      window.clearTimeout(window.orderStatusTimer);
      getOrderDetail(serialNum).then((res) => {
        if (res.code == 20000) {
          let status = res.data.status;
          // -3 退款 -2 失败， -1 取消， 0 未处理 1 支付中 2 已支付
          if (status == -3) {
            this.$baseMessage(
              "此订单已退款!",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == -2) {
            this.$baseMessage(
              "订单已失败!请稍后再试",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == 1) {
            let that = this;
            window.orderStatusTimer = setTimeout(function () {
              that.getOrderDetail(serialNum);
            }, 1000);
          } else if (status == 0) {
            this.$baseMessage(
              "订单未处理!请稍后再试",
              "error",
              "vab-hey-message-error"
            );
          } else if (status == 2) {
            this.$baseMessage(
              "恭喜您已订购成功！",
              "success",
              "vab-hey-message-success"
            );
            this.closePay();
            this.fileShow = true;
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },

    handleProduct(row) {
      this.courseId = row.id;
      this.getCourseTree(this.courseId);
    },
    async getCourseTree(id) {
      const { code, data } = await getProductBouquById(id);
      if (code == 20000) {
        this.detailFrom = data;
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
      this.getProductBouque();
    },

    // 查看课程
    getProductBouque() {
      let params = {
        published: true, // 已发布
        __page: 0,
        __pageSize: 10,
      };
      this.productList = [];
      getProductBouque(params).then((res) => {
        if (res.code == 20000) {
          let productList = res.data;
          productList.forEach((element) => {
            if (element.id != this.courseId) {
              this.productList.push(element);
            }
          });
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page1-container {
  flex: 1;
  padding: 0px 0px 35px 0;
  .w1200 {
    width: 1200px;
    margin: 0 auto;
  }
  .AskForMian {
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .AskForFromTable {
      margin: 0 -15px;
      border: 0;
      padding-bottom: 20px;
      td {
        padding: 0 15px;
        padding-bottom: 30px;
        box-sizing: border-box;
        width: 50%;
        .FontTitle {
          line-height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .FontInput {
          height: 45px;
          background: #ffffff;
          border: 1px solid #989898;

          border-radius: 3px;
          box-sizing: content-box;
          font-size: 0;
          input {
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 0 15px;
            height: 45px;
            font-size: 16px;
            border: 0;
            background: transparent;
          }
        }
        .FontTextarea {
          background: #ffffff;
          border: 1px solid #989898;

          border-radius: 3px;
          box-sizing: content-box;
          font-size: 0;
          textarea {
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 7px 15px;
            font-size: 16px;
            border: 0;
            background: transparent;
            line-height: 24px;
            font-family: "Microsoft YaHei";
          }
        }
        ul {
          li {
            padding: 10px 0;
            line-height: 24px;
          }
        }
      }
    }
    .AskForButton {
      background: #173d7c;
      border-radius: 10px;
      cursor: pointer;
      border: 0;
      font-size: 16px;
      color: #ffffff;
      width: 149px;
      height: 50px;

      &:hover {
        background: #244f96;
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
.rlineHeight {
  padding-top: 15px;
  line-height: 50px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #6b6c6d;
  > span {
    cursor: pointer;
  }
}

.PurchasePageShow_InfoMain {
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  padding: 35px;
  opacity: 1;
  border: 1px solid #aaaaaa;

  &::after {
    clear: both;
    display: block;
    content: "";
  }
  .PurchasePageShow_InfoMain_left {
    width: 610px;
    img {
      width: 100%;
    }
    float: left;
    font-size: 0;
  }
  .PurchasePageShow_InfoMain_right {
    padding-left: 650px;
    .PurchasePageShow_InfoMain_right_title {
      font-size: 24px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #171717;
      line-height: 36px;
    }
    .PurchasePageShow_InfoMain_right_font {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #181818;
      line-height: 24px;
      margin-top: 20px;
      height: 120px;
    }
  }
}
.PurchasePageShow_InfoMain_money {
  font-size: 24px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #ff7700;
  padding: 20px 0;
}
.PurchasePageShow_InfoMain_button {
  width: 149px;
  height: 50px;
  background: #173d7c;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.purcchaseAxpiresAt {
  margin-top: 20px;
}
.PurchasePage_Ninfo {
  padding-top: 20px;
  &::after {
    clear: both;
    display: block;
    content: "";
  }
  .PurchasePage_Ninfo_left {
    float: left;
    width: 850px;
    min-height: 674px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 1px solid #aaaaaa;
  }
  .PurchasePage_Ninfo_right {
    float: right;
    width: 320px;
    .PurchasePage_Ninfo_right_img {
      cursor: pointer;
      font-size: 0;
      img {
        width: 100%;
      }
    }
  }
}
.PurchasePage_Ninfo_ul {
  padding: 10px 15px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #aaaaaa;
  margin-top: 20px;
  .PurchasePage_Ninfo_ul_title {
    line-height: 45px;
    height: 45px;
    border-bottom: 1px solid #d1cece;
    opacity: 1;
    .fl {
      float: left;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #6b6c6d;
    }
    .fr {
      float: right;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #6b6c6d;
    }
  }
  ul {
    li {
      margin-top: 20px;
      &::after {
        clear: both;
        display: block;
        content: "";
      }
      .flimg {
        float: left;
        width: 152px;
        height: 90px;
        img {
          width: 100%;
          // height: 100%;
        }
      }
      .flimg-row {
        padding-left: 170px;
        .flimg-row-title {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #6b6c6d;
          line-height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .flimg-row-font {
          height: 50px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #6b6c6d;
          line-height: 16px;
          margin-top: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .flimg-row-int {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #9f9f9f;
          line-height: 16px;
          margin-top: 10px;
        }
      }
    }
  }
}
.purchaseiFooter {
  padding: 0 !important;
  .purchaseiFooter-top {
    background-color: #1d1d24;
    padding: 60px 0;
    .footer_left {
      float: left;
      .footer_left_font {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #808080;
        line-height: 28px;
        margin-top: 15px;
      }
    }
    .footer_right {
      float: right;
      .fl_div {
        float: left;
        margin-left: 55px;
        .fl_dev_title {
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #808080;
        }
        .fl_div_title_1 {
        }
      }
    }
    &::after {
      clear: both;
      display: block;
      content: "";
    }
  }
  .purchaseiFooter-bottom {
    background-color: #173d7c;
    height: 100px;
    line-height: 100px;
    color: #ffffff;
    &::after {
      clear: both;
      display: block;
      content: "";
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
  }
  .w1200 {
    margin: 0 auto;
    width: 1200px;
    &::after {
      clear: both;
      display: block;
      content: "";
    }
  }
  .fl_dev_font {
    margin-top: 20px;
    font-size: 16px;
    color: #808080;
    line-height: 30px;
    a:link,
    a:visited {
      color: #808080;
      text-decoration: none;
    }
    a:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt30 {
    margin-top: 30px;
  }
}

.NewPayShow {
  background: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 80px 30px 20px 30px;
  .NewTopicInfo {
    border-radius: 6px;
    background: #ffffff;
    width: 830px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    .infoTitle {
      border: 1px solid #edd54b;
      box-shadow: 2px 2px 2px 2px rgba(255, 246, 150, 0.5);
      margin: 0 35px;
      padding: 15px 20px;
      background: #fefade;
      color: #000;
      border-radius: 6px;
      text-align: center;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        line-height: 50px;
      }
      .title-font {
        font-size: 16px;
        font-weight: 500;
        // color: #000;
        // padding-bottom: 10px;
        text-align: left;
        color: #edd54b;
      }
    }
    .warBr {
      position: relative;
      border: 1px solid #cccccc;
      padding: 0px;
      margin-top: 20px;
      text-align: center;
      width: 40%;
      img {
        width: 100%;
      }
      .w-logo {
        width: 48px;
        height: 48px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -24px;
        margin-left: -24px;
        background: #090976;
        padding: 2px;
        border: 3px solid #fff;
        border-radius: 3px;
      }
    }
    .warBr-title {
      text-align: center;
      line-height: 36px;
    }
    .NewTopic-ul {
      padding-top: 30px;
      ul {
        li {
          padding: 12px 30px;
          &::after {
            clear: both;
            display: block;
            content: "";
          }
          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }
    .close {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: url("~@/assets/icon-gb.png") no-repeat center;
      background-size: cover;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.showFile {
  background: rgba(67, 67, 67, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 0 50px;
  z-index: 999;
  .showFile-info {
    width: 650px;
    height: 520px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    margin-top: -210px;
    .kefu-cont {
      width: 650px;
      height: 520px;
      padding-top: 40px;
      .kefu-title {
        margin: 0 auto;
        width: 85%;
        background: #173d7c;
        color: #ffffff;
        text-align: center;
        height: 65px;
        border-radius: 8px;
        opacity: 1;
        font-size: 20px;
        font-weight: 400;
        line-height: 65px;
      }
      .qrcode {
        margin: 0 auto;
        width: 220px;
        text-align: center;
        padding-top: 40px;
        > img {
          width: 100%;
          border: 1px solid #333333;
        }
      }
      .wechat {
        width: 60px;
        margin: 10px auto;
        >img {
          width: 100%;
        }
      }
      .wechat-text {
        text-align: center;
        font-weight: 600;
        font-size: 22px;
        color: #000000;
      }
    }
    .left-msg {
      padding-left: 30px;
      height: 80px;
      display: flex;
      align-items: center;
      > img {
        width: 32px;
        height: 32px;
      }
      span {
        color: #cccccc;
        font-size: 14px;
      }
    }

    .showFil-conent {
      padding: 0 65px;
      .title {
        font-size: 20px;
        font-weight: 600;
      }
      .title-text {
        font-size: 18px;
        line-height: 40px;
      }
      .title2 {
        font-size: 16px;
        font-weight: 600;
        margin-top: 20px;
      }
      .info-font {
        line-height: 30px;
      }
    }
  }
  .showFile-info i {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  .showFile-info i img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .showFile-info-button {
    width: 130px;
    height: 50px;
    background: #173d7c;
    border-radius: 6px;
    margin: 0 auto;
    line-height: 50px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    margin-top: 70px;
  }
  .showFile-info-button:hover {
    background: #10408f;
  }
  .showFile-info-h {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 20px;
    text-align: center;
  }
  .showFile-info-h span {
    border-bottom: 1px solid #666666;
    display: inline-block;
    cursor: pointer;
    line-height: 20px;
  }
}
</style>

<style lang="scss">
.richText {
  > p {
    > img {
      width: 100% !important;
      object-fit: fill;
    }
  }
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
</style>
