<template>
  <div class="AskForLogo">
    <img  @click="$router.push({path: '/PurchasePage'})" src="~@/assets/askFor/AskForLogo.png" alt="logo" />
  </div>
</template>

<script>
export default {
  name: "AskForLogo",
};
</script>

<style lang="scss" scoped>
.AskForLogo {
  float: left;
  padding-top: 18px;
  h1 {
    font-size: 18px;
    color: $color-white;
    font-weight: normal;
    margin-left: 5px;
  }

  img {
    height: 66px;
  }
}
</style>
