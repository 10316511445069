<template>
  <div class="about">
    <h1 @click="playTest">This is an about page</h1>
    <video
      width="1280"
      height="720"
      id="player-container-id0"
      ref="playerContainer"
      preload="auto"
      playsinline
      class="tc-video-container"
      webkit-playinline
      x5-playinline
    ></video>
    <div class="video player" id="tcplayer" ref="tcplayer"></div>

  </div>
</template>
<script>
// import TcAdapter from "tcadapter";

export default {
  name: "EduHome",
  data() {
    return {
      adapter: null,
    };
  },

  mounted() {
    //this.play();
  },
  destroyed() {},
  beforeDestroy() {
    if (this.adapter != null) {
      this.adapter.dispose();
    }
  },
  created() {
    let self = this;
    setTimeout(() => {
      self.$nextTick(() => {});
    }, 100);
  },
  methods: {
    playTest() {
      this.adapter = new TcAdapter(
        "player-container-id0",
        {
          appID: "1309121823",
          fileID: "387702306939370434",
        },
        function () {
          //alert(JSON.stringify(self.adapter.getVideoBasicInfo()));
          //console.log(self.adapter.videoWidth());
          //self.adapter.duration()
          // self.adapter.on(TcAdapter.TcAdapterEvents.HLSREADY, () => {
          //   alert("ddd")
          //   const hls = self.adapter.hls;
          //   alert(hls)
          //   // ...
          // });
          // alert(self.adapter.getStreamimgOutputList());
        }
      );
      var self = this;
      this.adapter.on("error", function (error) {
        alert(error);
        // 做一些处理
      });
      this.adapter.on("hlsready", () => {
        //const hls = adapter.hls;
        alert(self.adapter.getStreamimgOutputList());
        // ...
      });
      //var self = this;
      this.adapter.on("loadstart", () => {
        //const hls = adapter.hls;
        // ...
        //self.$refs.playerContainer.play()
        //alert("test");
      });
      this.$refs.playerContainer.play();
      //alert(this.adapter.videoWidth())
    },
    play() {
      let dom = document.getElementById("tcplayer");
      // let dom = this.$refs.tcplayer;
      // console.log(dom);
      while (dom.hasChildNodes()) {
        //当div下还存在子节点时 循环继续
        dom.removeChild(dom.firstChild);
        this.player.destroy();
      }
      this.player =  this.TcPlayer("tcplayer", {
        appID: "1309121823",
        fileID: "387702306939370434",
        psign: "",
        autoplay: false,
        controls: true,
        mp4: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",
        listener: this.listener,
        // });
      });
    },
    listener() {},
  },
};
</script>
<style scoped>
.player {
  position: relative;
  width: 100%;
  //height:100%;
  height: 26vh;

  .video {
    width: 100%;
    height: 100%;

    .vcp-player {
      width: 100%;
      height: 100%;

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

</style>
<style scoped >
@import url("//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.css");
</style>
