<template>
  <div class="EduHome">
    <PurchasePageHeader />
    <div class="PurchasePage_Banner">
      <!-- <div class="left" @click="butFn(1)"></div>
      <div class="right" @click="butFn(2)"></div> -->
      <div class="fiexd-btn" @click="$router.push({ path: '/purchaseMore' })">
        开始选课
      </div>
      <div class="carousel">
        <el-carousel
          trigger="click"
          height="25.298vw"
          arrow="hover"
          :interval="4000"
          indicator-position="none"
        >
          <el-carousel-item
            v-for="(item, index) in bannerList"
            :key="index + 'carousel'"
            :style="'background-image:url(' + item.url + ');'"
          >
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <ul>
        <li :style="'background-image:url('+item.url+');'" v-for="(item,i) in bannerList" :key="i" :class="{'on':i==index}"> -->
      <!-- <li :style="'background-image:url('+item.id+');'" v-for="(item,i) in bannerList" :key="i" :class="{'on':i==index}">
          <div class="w1100">
            <div class="fr">
              <img :src="item.url" />
            </div>
          </div> -->
      <!-- </li>
      </ul> -->
      <!-- 视频  -->
      <div class="containerCenter-video" v-if="courseData.coverVideo != ''">
        <video-player
          id="videoPlayer1"
          ref="videoPlayer1"
          class="vjs-custom-skin"
          :options="playerOptions1"
          @fullscreenchange="fullscreenchange"
          @showvideodocument="showVideoDocument"
          @ended="onPlayerEnded($event)"
        />
      </div>
    </div>

    <main>
      <div class="page1-container">
        <div class="w1200">
          <div class="tags">
            <div class="item-tags" v-for="item in tags" :key="item" @click="handleTag(item)">{{ item }}</div>
          </div>
        </div>
        <div class="w1200">
          <div class="PurchasePage_title">
            <span>推荐课程</span>
          </div>
          <div class="PurchasePage_ul">
            <ul>
              <li v-for="(item, index) in courseList" :key="index + 'four'">
                <div class="PurchasePage_Item" @click="handleDetail(item)">
                  <div class="PurchasePage_Item_img">
                    <img v-if="item.image" :src="item.image" />
                    <img v-else src="../../assets/PurchasePage/timg.png" />
                  </div>
                  <div class="PurchasePage_Main">
                    <div class="PurchasePage_Mian_title" :title="item.name">
                      {{ item.name }}
                    </div>
                    <div class="PurchasePage_Mian_font" :title="item.summary">
                      {{ item.summary }}
                      <!-- <span v-html="item.introduction"></span> -->
                    </div>
                    <div class="PurchasePage_Mian_money">
                      <div class="money_fl">
                        {{ (item.price / 100).toFixed(2) }} 元
                      </div>
                      <div class="money_fr">
                        <img src="../../assets/PurchasePage/gwleft.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="PurchasePage_Main_more"
            @click="$router.push({ path: '/purchaseMore' })"
          >
            <a href="javascript:;"><span>更多课程</span><i></i></a>
          </div>
        </div>
      </div>
      <div class="page1-container" style="background: #f4f4f4">
        <div class="w1200">
          <div class="PurchasePage_title">
            <span>互动教学平台</span>
          </div>
          <div class="PurchasePage_InfoMain">
            <div class="PurchasePage_InfoMain_left">
              <img :src="cpaWeb2Data.url" />
            </div>
            <div class="PurchasePage_InfoMain_right">
              <div class="PurchasePage_InfoMain_right_title">
                <!-- 华路数字文化CPA沉浸式教学互动平台 -->
                {{ cpaWeb2Data.title }}
              </div>
              <div
                class="PurchasePage_InfoMain_right_font"
                v-html="cpaWeb2Data.content"
              >
                <!-- 华路数字文化CPA沉浸式互动教学平台，在数字化环境中还原全球顶尖商学院线下教学里最好的学习方式，为你打造高效学习空间，帮助你在任何空余时间获取知识。 -->
              </div>
              <!-- <div class="PurchasePage_InfoMain_right_a" @click="$router.push({path: '/purchaseitem'})">
                <a href="#"><span>了解更多</span><i></i></a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer class="purchaseiFooter">
      <div class="purchaseiFooter-top">
        <div class="w1200">
          <div class="footer_left">
            <div><img src="../../assets/footer_logo.png" /></div>
            <div class="footer_left_font">
              华路数字文化<br />CPA沉浸式教学互动平台
            </div>
          </div>
          <div class="footer_right">
            <div class="fl_div">
              <div class="fl_dev_title">快捷服务</div>
              <div class="fl_dev_font">
                <p>
                  <a href="http://www.cufehl.com" target="_balnk">关于我们</a>
                </p>
                <p @click="$router.push({ path: '/purchaseitemInfo' })">
                  <a href="#">联系我们</a>
                </p>
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">微信公众号</div>
              <div class="mt20">
                <img src="../../assets/footer_code.png" />
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">客服/咨询</div>
              <div
                class="mt30"
                @click="$router.push({ path: '/purchaseitemInfo' })"
              >
                <img src="../../assets/title_1.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purchaseiFooter-bottom">
        <div class="w1200">
          <div class="fl">COPYRIGHT © 2009-2020 华路数字文化 版权所有</div>
          <div class="fr">粤ICP备14094585号</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { getProductBouque, getProductTags } from "@/api/product.js";
import { getBannerList } from "@/api/materialBannerController.js";
import { getMediaFileInfo } from "@/api/materialController";
import PurchasePageHeader from "@/components/PurchasePageHeader";
// import "video.js/dist/video-js.css";
// import "@/components/VueVideoPlayer/custom-theme.css";
import videoPlayer from "@/components/VueVideoPlayer/player.vue";
export default {
  name: "AskFor",
  data() {
    return {
      tags: [],
      courseList: [],
      index: 0,
      bannerBgColorList: ["#244f96", "#68a38d", "#745474", "#729f96"],
      bannerList: [
        {
          id: 1,
          url: require("../../../public/images/carousel1.png"),
        },
        {
          id: 2,
          url: require("../../../public/images/carousel2.png"),
        },
        {
          id: 3,
          url: require("../../../public/images/carousel3.png"),
        },
      ],
      courseData: {
        coverVideo:
          "https://cpa.cufehl.com/upload/video/42/d7ee/2bda/bdf2/a8c7beddd4031fcbc3.mp4",
      },
      cpaWeb2Data: {
        url: require("../../../public/images/timg.png"),
        title: "华路数字文化CPA沉浸式教学互动平台",
        content:
          "华路数字文化CPA沉浸式互动教学平台，在数字化环境中还原全球顶尖商学院线下教学里最好的学习方式，为你打造高效学习空间，帮助你在任何空余时间获取知识。",
      },
      playerOptions1: {
        playbackRates: [0.7, 1.0, 1.25, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        controls: true, // 控制条
        preload: "auto", // 视频预加载
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", // 你的封面地址
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        isShowVideoDocBtn: true,
      },
    };
  },
  components: {
    PurchasePageHeader,
    videoPlayer,
  },

  methods: {
    handleTag (tags) {
      this.$router.push({ 
        path: '/purchaseMore',
        query:{
          tags: tags
        }
      })
    },
    butFn(row) {
      if (row == 1) {
        this.index--;
        if (this.index < 0) {
          this.index = this.bannerList.length - 1;
        }
      }
      if (row == 2) {
        this.index++;
        if (this.bannerList.length <= this.index) {
          this.index = 0;
        }
      }
    },

    // 查看课程详情
    handleDetail(row) {
      this.$router.push({
        path: "/purchaseShow",
        query: {
          id: row.id,
        },
      });
    },
    onPlayerEnded(event) {
      //播放结束
    },
    setPlay2(videoUrl) {
      getMediaFileInfo({ filePath: videoUrl }).then((res) => {
        if (res.code == 20000) {
          this.mediaFileData = res.data;
          if (res.data.deliveryUrl != "" && res.data.deliveryUrl != null) {
            this.playerOptions1.sources = [
              {
                type: "video/mp4",
                src: res.data.uri == null ? videoUrl : res.data.uri,
                fileID: res.data.deliveryUrl,
              },
            ];
          } else {
            this.playerOptions1.sources = [
              {
                type: "video/mp4",
                src: res.data.uri == null ? videoUrl : res.data.uri,
              },
            ];
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getProductBouque() {
      let params = {
        published: true,
        __orderBy: "-featured",
      };
      getProductBouque(params).then((res) => {
        if (res.code == 20000) {
          if (res.data && res.data.length > 4) {
            this.courseList = res.data.slice(0, 4);
          } else {
            this.courseList = res.data;
          }
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },

    fullscreenchange() {
      this.isFullscreen = !this.isFullscreen;
    },

    setPlay1(type, url) {
      this.playerOptions1.sources = [{ type: type, src: url }];
    },
    showVideoDocument() {
      if (this.isFullscreen) {
        //不显示视频文稿
        //alert("不显示视频文稿");
      } else {
        this.isShowVideoDocument = !this.isShowVideoDocument;
        //alert("显示视频文稿");
      }
    },

    closePlay() {
      if (
        this.$refs.videoPlayer1 &&
        this.$refs.videoPlayer1.player &&
        this.$refs.videoPlayer1.player.hasStarted_
      ) {
        this.$refs.videoPlayer1.player.pause();
      }
    },
    getBannerListCpaWeb1() {
      var params = {
        position: "CPA-WEB-1",
        published: true,
        __page: 0,
        __pageSize: 10,
        __orderBy: "idx",
      };
      getBannerList(params).then((res) => {
        if (res.code == 20000) {
          if (res.data && res.data.length > 0) {
            let length = res.data.length;
            let data = [];
            let videoUrl = "";
            for (let i = 0; i < length; i++) {
              let url = res.data[i].fullImage;
              if (videoUrl == "") {
                videoUrl = res.data[i].videoUrl;
              }
              if (url == "") {
                url = res.data[i].thumbImage;
              }
              data.push({ id: i, url: url });
            }
            if (data.length > 0) {
              this.bannerList = data;
            }
            if (videoUrl != "") {
              this.courseData.coverVideo = videoUrl;
            }
          }
          this.setPlay2(this.courseData.coverVideo);
          //this.setPlay1("video/mp4", this.courseData.coverVideo);
        } else {
          //this.setPlay1("video/mp4", this.courseData.coverVideo);
          this.setPlay2(this.courseData.coverVideo);
          //this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    getBannerListCpaWeb2() {
      var params = {
        position: "CPA-WEB-2",
        published: true,
        __page: 0,
        __pageSize: 10,
        __orderBy: "idx",
      };
      getBannerList(params).then((res) => {
        if (res.code == 20000) {
          if (res.data && res.data.length > 0) {
            let data = res.data[0];
            if (data.thumbImage != "") {
              this.cpaWeb2Data.url = data.thumbImage;
            } else if (data.fullImage != "") {
              this.cpaWeb2Data.url = data.fullImage;
            }
            if (data.summary != "") {
              this.cpaWeb2Data.title = data.summary;
            }
            if (data.content != "") {
              this.cpaWeb2Data.content = data.content;
            }
          }
        } else {
          //this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },

    // 分类
    async categoryFn() {
      const { code, data } = await getProductTags();
      if (code == 20000) {
        this.tags = data;
        // this.tags.unshift({id: '', name: '全部'})
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },
  },

  mounted() {},

  created() {
    this.getProductBouque();
    this.getBannerListCpaWeb1();
    this.getBannerListCpaWeb2();
    this.categoryFn()
  },
};
</script>

<style lang="scss" scoped>
.page1-container {
  flex: 1;
  padding: 0px 0px 35px 0;
  .w1200 {
    width: 1200px;
    margin: 0 auto;
  }
  .tags {
    margin-top: 57px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between; 
    .item-tags {
      width: 381px;
      height: 79px;
      line-height: 79px;
      text-align: center;
      color: #333333;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #707070;
      font-size: 24px;
      margin-bottom: 20px;
      cursor: pointer;
      &:hover{
        color: #ffffff;
        background: linear-gradient(180deg, #173D7C 0%, #0C1F3E 100%);
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
      }
    }
  }
  .AskForMian {
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .AskForFromTable {
      margin: 0 -15px;
      border: 0;
      padding-bottom: 20px;
      td {
        padding: 0 15px;
        padding-bottom: 30px;
        box-sizing: border-box;
        width: 50%;
        .FontTitle {
          line-height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .FontInput {
          height: 45px;
          background: #ffffff;
          border: 1px solid #989898;

          border-radius: 3px;
          box-sizing: content-box;
          font-size: 0;
          input {
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 0 15px;
            height: 45px;
            font-size: 16px;
            border: 0;
            background: transparent;
          }
        }
        .FontTextarea {
          background: #ffffff;
          border: 1px solid #989898;

          border-radius: 3px;
          box-sizing: content-box;
          font-size: 0;
          textarea {
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 7px 15px;
            font-size: 16px;
            border: 0;
            background: transparent;
            line-height: 24px;
            font-family: "Microsoft YaHei";
          }
        }
        ul {
          li {
            padding: 10px 0;
            line-height: 24px;
          }
        }
      }
    }
    .AskForButton {
      background: #173d7c;
      border-radius: 10px;
      cursor: pointer;
      border: 0;
      font-size: 16px;
      color: #ffffff;
      width: 149px;
      height: 50px;

      &:hover {
        background: #244f96;
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
.PurchasePage_Banner {
  height: 425px;
  position: relative;
  background: #244f96;
  .left {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -45px;
    margin-left: -650px;
    width: 49px;
    height: 90px;
    background: url("../../assets/PurchasePage/left.png") no-repeat left;
    z-index: 1;
    cursor: pointer;
  }
  .right {
    position: absolute;
    right: 50%;
    top: 50%;
    margin-top: -45px;
    margin-right: -650px;
    width: 56px;
    height: 90px;
    background: url("../../assets/PurchasePage/right.png") no-repeat left;
    z-index: 1;
    cursor: pointer;
  }
  .fiexd-btn {
    width: 180px;
    height: 58px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #173d7c;
    line-height: 58px;
    text-align: center;

    position: absolute;
    left: 43%;
    top: 50%;
    margin-top: 87px;
    margin-left: -385px;
    z-index: 9;
    cursor: pointer;
  }

  .containerCenter-video {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -143px;
    margin-left: -33px;
    z-index: 9;
    width: 580px;
    // margin-top: 15px;
    video {
      height: 450px;
      width: 100%;
      background: #000000;
    }
  }

  ul {
    height: 425px;
    width: 100%;
    position: relative;
    li {
      height: 425px;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      display: none;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &.on {
        display: block;
      }
      .fl {
        float: left;
      }
      .fr {
        float: right;
        margin-top: 70px;
      }
    }
  }
  .w1100 {
    width: 1100px;
    margin: 0 auto;
  }
}
.PurchasePage_title {
  height: 110px;
  line-height: 110px;
  text-align: center;
  position: relative;
  span {
    display: inline-block;
    position: relative;
    padding: 0 50px;
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 500;
    color: #6b6c6d;

    &::after {
      position: absolute;
      top: 53px;
      left: 0;
      width: 40px;
      display: block;
      content: "";
      height: 3px;
      background: #f3c912;
    }
    &::before {
      position: absolute;
      top: 53px;
      right: 0;
      width: 40px;
      display: block;
      content: "";
      height: 3px;
      background: #f3c912;
    }
  }
}
.PurchasePage_ul {
  margin: 0 -6px;
  &::after {
    clear: both;
    display: block;
    content: "";
  }
  ul {
    li {
      float: left;
      width: 25%;
      box-sizing: border-box;
      padding: 0 6px;
      .PurchasePage_Item {
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #979797;
        overflow: hidden;
        .PurchasePage_Item_img {
          height: 166px;
          img {
            width: 100%;
            // height: 100%;
          }
        }
        .PurchasePage_Main {
          padding: 0 25px;
          .PurchasePage_Mian_title {
            font-size: 24px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #171717;
            padding: 20px 0;
            line-height: 30px;
            overflow: hidden; // 溢出部分隐藏
            white-space: nowrap; // 文字不换行
            text-overflow: ellipsis; // 显示省略号
          }
          .PurchasePage_Mian_font {
            height: 4vw;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #181818;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .PurchasePage_Mian_money {
            padding: 20px 0;
            &::after {
              clear: both;
              display: block;
              content: "";
            }
            .money_fl {
              float: left;
              font-size: 24px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #ff7700;
            }
            .money_fr {
              float: right;
            }
          }
        }
      }
    }
  }
}
.PurchasePage_Main_more {
  padding-top: 35px;
  text-align: center;
  a:link,
  a:visited {
    display: inline-block;
    width: 134px;
    height: 35px;
    background: #ffffff;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    line-height: 33px;
    border: 1px solid #173d7c;
    span {
      display: inline-block;
      color: #173d7c;
    }
    i {
      display: inline-block;
      background: url("../../assets/PurchasePage/aheader.png") no-repeat center;
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }
}
.PurchasePage_InfoMain_right_a {
  padding-top: 35px;
  a:link,
  a:visited {
    text-align: center;
    display: inline-block;
    width: 134px;
    height: 35px;
    background: #ffffff;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    line-height: 33px;
    border: 1px solid #173d7c;
    span {
      display: inline-block;
      color: #173d7c;
    }
    i {
      display: inline-block;
      background: url("../../assets/PurchasePage/aheader.png") no-repeat center;
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }
}
.PurchasePage_InfoMain {
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  padding: 35px;
  &::after {
    clear: both;
    display: block;
    content: "";
  }
  .PurchasePage_InfoMain_left {
    width: 455px;
    img {
      width: 100%;
    }
    float: left;
  }
  .PurchasePage_InfoMain_right {
    padding-left: 500px;
    .PurchasePage_InfoMain_right_title {
      font-size: 24px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #173d7c;
      padding: 20px 0;
    }
    .PurchasePage_InfoMain_right_font {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #585a59;
      line-height: 28px;
    }
  }
}
.purchaseiFooter {
  padding: 0 !important;
  .purchaseiFooter-top {
    background-color: #1d1d24;
    padding: 60px 0;
    .footer_left {
      float: left;
      .footer_left_font {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #808080;
        line-height: 28px;
        margin-top: 15px;
      }
    }
    .footer_right {
      float: right;
      .fl_div {
        float: left;
        margin-left: 55px;
        .fl_dev_title {
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #808080;
        }
        .fl_div_title_1 {
        }
      }
    }
    &::after {
      clear: both;
      display: block;
      content: "";
    }
  }
  .purchaseiFooter-bottom {
    background-color: #173d7c;
    height: 100px;
    line-height: 100px;
    color: #ffffff;
    &::after {
      clear: both;
      display: block;
      content: "";
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
  }
  .w1200 {
    margin: 0 auto;
    width: 1200px;
    &::after {
      clear: both;
      display: block;
      content: "";
    }
  }
  .fl_dev_font {
    margin-top: 20px;
    font-size: 16px;
    color: #808080;
    line-height: 30px;
    a:link,
    a:visited {
      color: #808080;
      text-decoration: none;
    }
    a:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt30 {
    margin-top: 30px;
  }
}

.carousel {
  .el-carousel__item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
