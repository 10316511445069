<template>
  <div class="EduHome">
    <PurchasePageHeader />
    <div class="purchaseitem_Banner">
      <div class="w1200">
        <div class="purchaseitem_Banner_font" @click="$router.push({ path: '/PurchasePage'})"><a href="#">首页</a>  /  华路互动教学平台</div>
        <div class="purchaseitem_Banner_main">
          <div class="purchaseitem_Banner_main_title">华路互动教学平台</div>
          <div class="purchaseitem_Banner_main_font">沉浸式案例学习+互动式教学工具<br>让你学习高效轻松</div>
        </div>
      </div>
    </div>
    <main>
      <div class="purchaseitem_font">
        <div class="purchaseitem_fontTitle">华路互动教学平台</div>
        <div class="purchaseitem_font_1">UMU 赋能培训工作者和教育工作者，<br />通过学习科学与学习技术，让学员爱上学习、学以致用。</div>
      </div>
      <div class="purchaseitem_item">
        <ul>
          <li>
            <div class="w1200">
              <div class="purchaseitem_item_img">
                <img src="../../assets/PurchasePage/04.png" />
              </div>
              <div class="purchaseitem_item_fr">
                <div class="fontitle">设计，交付，运营<br />学习项目一体化解决方案</div>
                <div class="fontS">
                  <p>更好的效果，更低的成本通过引入学习科学和学习技术，在线学习项目轻松实施</p>
                  <p>知识，技能，实操，价值观四大类常见类型学习项目一站式设计和运营支持</p>
                  <p>自动推送学习任务，基于数据轻松运营为学员自动推送学习任务，学习数据智能分析</p>
                  <p>融合游戏化理念，让学员爱上学习通过游戏化和社交化学习理念，让学员深度融入学习过程</p>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="w1200">
              <div class="purchaseitem_item_img">
                <img src="../../assets/PurchasePage/05.png" />
              </div>
              <div class="purchaseitem_item_fr">
                <div class="fontitle">极简智能的<br />教学和教研平台</div>
                <div class="fontS">
                  <p>极简智能的互动教学工具简单好用的智能教学助手，千万教师的共同选择</p>
                  <p>有效提高学习成绩通过间隔、重复地科学练习，有效提升学习成绩</p>
                  <p>助力教师专业成长互动式跨校区协同教研，助力教师提升</p>
                  <p>建立家庭学校紧密连接家长深度参与教学过程，让学校教学轻松高效</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </main>
    <footer class="purchaseiFooter">
      <div class="purchaseiFooter-top">
        <div class="w1200">
          <div class="footer_left">
            <div><img src="../../assets/footer_logo.png"></div>
            <div class="footer_left_font">华路数字文化<br>CPA沉浸式教学互动平台</div>
          </div>
          <div class="footer_right">
            <div class="fl_div">
              <div class="fl_dev_title">快捷服务</div>
              <div class="fl_dev_font">
                <p><a href="http://www.cufehl.com" target="_balnk">关于我们</a></p>
                <p @click="$router.push({path: '/purchaseitemInfo'})"><a href="#">联系我们</a></p>
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">微信公众号</div>
              <div class="mt20">
                <img src="../../assets/footer_code.png" />
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">客服/咨询</div>
              <div class="mt30" @click="$router.push({path: '/purchaseitemInfo'})">
                <img src="../../assets/title_1.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purchaseiFooter-bottom">
        <div class="w1200">
          <div class="fl">COPYRIGHT © 2009-2020 华路数字文化 版权所有</div>
          <div class="fr">粤ICP备14094585号</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import PurchasePageHeader from "@/components/PurchasePageHeader";
export default {
  name: "AskFor",
  data() {
    return {
      AskForFrom:{}
    };
  },
  components: {
    PurchasePageHeader
  },
  created() {},
  methods: {
    AskForBut(){ //提交索取
      console.log(this.AskForFrom)
    }
  }
};
</script>

<style lang="scss" scoped>
.page1-container {
  flex: 1;
  padding: 0px 0px 35px 0;
  .w1200{
    width: 1200px;
    margin: 0 auto;
  }
  .AskForMian{
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .AskForFromTable{
      margin: 0 -15px;
      border: 0;
      padding-bottom: 20px;
      td{
        padding: 0 15px;
        padding-bottom: 30px;
        box-sizing: border-box;
        width: 50%;
        .FontTitle{
          line-height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          
        }
        .FontInput{
          height: 45px;
          background: #FFFFFF;
          border: 1px solid #989898;
          border-radius: 3px;
          box-sizing:content-box;
          font-size: 0;
          input{
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 0 15px;
            height: 45px;
            font-size: 16px;
            border: 0;
            background: transparent;
          }
        }
        .FontTextarea{
          background: #FFFFFF;
          border: 1px solid #989898;
          border-radius: 3px;
          box-sizing:content-box;
          font-size: 0;
          textarea{
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 7px 15px;
            font-size: 16px;
            border: 0;
            background: transparent;
            line-height: 24px;
            font-family: 'Microsoft YaHei';
          }
        }
        ul{
          li{
            padding: 10px 0;
            line-height: 24px;
          }
        }
      }
    }
    .AskForButton{
      background: #173D7C;
      border-radius: 10px;
      cursor: pointer;
      border:0;
      font-size: 16px;
      color:#ffffff;
      width: 149px;
      height: 50px;
      &:hover{
        background: #244f96;
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter{
  border-top:1px solid #BFBFBF;
  .FooterCenter{
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul{
      float: left;
      li{
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight{
      float: right;
      text-align: right;
      p{
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}

.purchaseitem_Banner{
  height: 425px;
  position: relative;
  background: #ffffff url("../../assets/PurchasePage/banner1.png") no-repeat center;
  background-size: cover;
}
.w1200{
  width: 1200px;
  margin:0 auto
}
.purchaseitem_Banner_font{
  color: #ffffff;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 60px;
}
.purchaseitem_Banner_font a:link,.purchaseitem_Banner_font a:visited{
  color:#ffffff
}
.purchaseitem_Banner_main{
  text-align: center;
  color: #ffffff;
}
.purchaseitem_Banner_main_title{
  font-size: 60px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 100px;
}
.purchaseitem_Banner_main_font{
  font-size: 54px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 60px;
  margin-top:50px;
}
.purchaseitem_font{
  padding: 30px 0 50px 0;
  .purchaseitem_fontTitle{
    text-align: center;
    font-size: 46px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    padding: 20px 0;
  }
  .purchaseitem_font_1{
    text-align: center;
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #585A59;
    line-height: 30px;
  }
}
.purchaseitem_item{
  margin: 0 auto;
  ul{
    li{
      background: #F4F4F4;
      .w1200{
        width: 1200px;
        margin: 0 auto;
      }
      padding: 50px 0;
      &::after{
        clear: both;
        display: block;
        content: '';
      }
      .purchaseitem_item_img{
        float: left;
        width: 510px;
        margin-top: 30px;
        img{
          width: 100%;
        }
      }
      .purchaseitem_item_fr{
        float: right;
        width: 620px;
        .fontitle{
          font-size: 44px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #173D7C;
          line-height: 65px;
        }
        .fontS{
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #585A59;
          line-height: 26px;
          margin-top: 20px;
          p{
            padding: 15px 0;
          }
        }
      }
      &:nth-child(2n){
        background: #ffffff;
        .purchaseitem_item_img{
          float: right;
        }
        .purchaseitem_item_fr{
          float: left;
        }
      }
    }
  }
}
.purchaseiFooter{
  padding: 0 !important;
  .purchaseiFooter-top{
    background-color: #1D1D24;
    padding: 60px 0;
    .footer_left{
      float: left;
      .footer_left_font{
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #808080;
        line-height: 28px;
        margin-top: 15px;
      }
    }
    .footer_right{
      float: right;
      .fl_div{
        float: left;
        margin-left: 55px;
        .fl_dev_title{
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #808080;
        }
        .fl_div_title_1{

        }
      }
    }
    &::after{
      clear: both;
      display: block;
      content: '';
    }
  }
  .purchaseiFooter-bottom{
    background-color: #173D7C;
    height: 100px;
    line-height: 100px;
    color: #FFFFFF;
    &::after{
      clear: both;
      display: block;
      content: '';
    }
    .fl{
      float: left;
    }
    .fr{
      float: right;
    }
  }
  .w1200{
    margin: 0 auto;
    width: 1200px;
    &::after{
      clear: both;
      display: block;
      content: '';
    }
  }
  .fl_dev_font{
    margin-top: 20px;
    font-size: 16px;
    color: #808080;
    line-height: 30px;
    a:link,a:visited{
      color:#808080;
      text-decoration: none;
    }
    a:hover{
      color:#ffffff;
      text-decoration: none;
    }
  }
  .mt20{
    margin-top: 20px;
  }
  .mt30{
    margin-top: 30px;
  }
}
</style>