<template>
  <div class="EduHome">
    <PurchasePageHeader />
    <main>
      <div class="purchaseitemInfo">
        <div class="w1200">
          <div class="purchaseitemInfo_title">客服/咨询</div>
          <div class="purchaseitemInfo_main">
            <div class="purchaseitemInfo_main_title">基本信息</div>
            <div class="purchaseitemInfo_main_t1">联系邮箱</div>
            <div class="purchaseitemInfo_main_tInput">
              <input type="text" placeholder="请输入联系邮箱" v-model="consultForm.contact" />
            </div>
            <div class="purchaseitemInfo_main_t1">问题类型</div>
            <div class="purchaseitemInfo_main_tInput main_select">
              <select placeholder="请选择" v-model="consultForm.category">
                <option value="学习平台建议">学习平台建议</option>
                <option value="商城建议">商城建议</option>
                <option value="课程建议">课程建议</option>
                <option value="其它">其它</option>
              </select>
            </div>
            <div class="purchaseitemInfo_main_t1">主题</div>
            <div class="purchaseitemInfo_main_tInput">
              <input type="text" placeholder="请输入" v-model="consultForm.subject" />
            </div>
            <!-- <div class="purchaseitemInfo_main_t1">手机号</div>
            <div class="purchaseitemInfo_main_tInput">
              <input type="text" placeholder="请输入手机号码" v-model="consultForm.cellphone" />
            </div>
            <div class="purchaseitemInfo_main_t1">手机验证码</div>
            <div class="purchaseitemInfo_main_tInput">
              <input type="text" placeholder="请输入手机验证码" v-model="consultForm.captcha" />
              <button v-show="show" @click="checkSendSmscode" class="FontButton">获取验证码</button>
              <button v-show="!show" class="FontButton" type="button">
                      重新获取{{ count }}S
                    </button>
            </div> -->
            <div class="purchaseitemInfo_main_title">咨询问题留言</div>
            <div class="purchaseitemInfo_main_tInput">
              <textarea placeholder="咨询问题(500字内)" v-model="consultForm.content" :maxlength="500"></textarea>
            </div>
          </div>
          <div class="p30">
            <button class="purchaseitemInfo_main_button" @click="submit">提交</button>
          </div>
        </div>
      </div>
    </main>
    <!-- 弹窗 -->
    <div class="showFile" v-if="fileShow">
      <div class="showFile-info">
        <i @click="fileShow = false"><img src="~@/assets/icon-gb.png" /></i>
        <div class="showFile-info-font">
          您未登录不能留言哦, 请您登录后再留言~
        </div>
        <div
          class="showFile-info-button"
          @click="toLogin"
        >
        去登录 / 注册
        </div>
      </div>
    </div>

    <div class="showFile" v-if="leaveShow">
      <div class="showFile-info" style="width: 450px; height: 280px;">
        <i @click="leaveShow = false"><img src="~@/assets/icon-gb.png" /></i>
        <div class="showFile-info-font">
          留言成功~
        </div>
        <div
          class="showFile-info-button"
          @click="$router.push({ path: '/PurchasePage'})"
        >
        去浏览课程
        </div>
        <div class="showFile-info-h">
          <span @click="leaveShow= false">留在本页</span>
        </div>
      </div>
    </div>

    <footer class="purchaseiFooter">
      <div class="purchaseiFooter-top">
        <div class="w1200">
          <div class="footer_left">
            <div><img src="../../assets/footer_logo.png"></div>
            <div class="footer_left_font">华路数字文化<br>CPA沉浸式教学互动平台</div>
          </div>
          <div class="footer_right">
            <div class="fl_div">
              <div class="fl_dev_title">快捷服务</div>
              <div class="fl_dev_font">
                <p><a href="http://www.cufehl.com"  target="_balnk">关于我们</a></p>
                <p><a href="#">联系我们</a></p>
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">微信公众号</div>
              <div class="mt20">
                <img src="../../assets/footer_code.png" />
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">客服/咨询</div>
              <div class="mt30">
                <img src="../../assets/title_1.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purchaseiFooter-bottom">
        <div class="w1200">
          <div class="fl">COPYRIGHT © 2009-2020 华路数字文化 版权所有</div>
          <div class="fr">粤ICP备14094585号</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { sendSmscode, createIssue } from "@/api/authController";
import PurchasePageHeader from "@/components/PurchasePageHeader";
export default {
  name: "AskFor",
  data() {
    return {
      leaveShow: false,
      fileShow: false,
      show: true,
      count: "",
      timer: null,
      consultForm:{
        // cellphone: '', 
        // captcha: '',
        content: '',
        contact: '',
        category: '',
        subject: "",
      }
    };
  },
  components: {
    PurchasePageHeader
  },
  created() {},
  methods: {
    // 登录页面
    toLogin () {
      this.$router.push({
        path: "/Login",
        query: { redirect: this.$route.path },
        replace: true,
      })
      this.fileShow = false
    },

    // 获取验证码
    checkSendSmscode() {
      if (!this.consultForm.cellphone) {
        this.$baseMessage(
          "请填写手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.consultForm.cellphone)) {
        this.$baseMessage(
          "请输入有效的手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      this.sendSmscode()
    },
    // 发送验证码
    async sendSmscode() {
      let json = {};
      // json.captcha = '1234';
      json.cellphone = this.consultForm.cellphone;
      sendSmscode(json).then((res) => {
        if (res.code == 20000) {
          this.getCode();
        } else {
          this.$baseMessage(
            "code:" + res.code + ",msg:" + res.msg,
            "error",
            "vab-hey-message-error"
          );
          // this.consultForm.captcha = "";
        }
      });
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    //提交 不需要验证号码
    submit(){ 
      if (this.$store.state.isLogout) {
        // 未登录  先登录在反馈
        this.fileShow = true
        return 
      }

      if (!this.consultForm.subject) {
        this.$baseMessage(
          "请填写主题",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }

      if (!this.consultForm.content) {
        this.$baseMessage(
          "请填写咨询问题",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      
      createIssue(this.consultForm).then((res) => {
        if (res.code == 20000 || res.code == 20100) {
           // 提交成功
          this.consultForm = {
            contact: '', 
            type: '',
            content: ''
          }
          this.leaveShow= true
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });


      // var reg = /^1[3456789]\d{9}$/;
      // if (!reg.test(this.consultForm.cellphone)) {
      //   this.$baseMessage(
      //     "请输入有效的手机号码",
      //     "warning",
      //     "vab-hey-message-warning"
      //   );
      //   return;
      // }
      // if (!this.consultForm.captcha) {
      //   this.$baseMessage(
      //     "请填写手机验证码",
      //     "warning",
      //     "vab-hey-message-warning"
      //   );
      //   return;
      // }
      // if (this.consultForm.captcha.length != 6) {
      //   this.$baseMessage(
      //     "手机验证码位数不对",
      //     "warning",
      //     "vab-hey-message-warning"
      //   );
      //   return;
      // }
    },

  }
};
</script>

<style lang="scss" scoped>
.page1-container {
  flex: 1;
  padding: 0px 0px 35px 0;
  .w1200{
    width: 1200px;
    margin: 0 auto;
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}

.purchaseitemInfo{
  position: relative;
  .w1200{
    width: 1200px;
    margin: 0 auto;
  }
  .purchaseitemInfo_title{
    font-size: 36px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 80px;
  }
  .p30{
      padding: 50px 0;
      .purchaseitemInfo_main_button{
        width: 149px;
          height: 50px;
          background: #173D7C;
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          border: 0;
      }
      
    }
  .purchaseitemInfo_main{
    background: #F2F3F3;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px solid #D8D8D8;
    padding: 30px 110px;
    .purchaseitemInfo_main_title{
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      font-size: 32px;
      line-height: 80px;
    }
    .purchaseitemInfo_main_t1{
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }
    .purchaseitemInfo_main_tInput{
      &::after{
        clear: both;
        display: block;
        content: '';
      }
      input{
        width: 432px;
        height: 45px;
        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        border: 1px solid #989898;
        padding: 0 15px;
        float: left;
      }
      button{
        font-size: 20px;
        font-family: Segoe UI-Regular, Segoe UI;
        font-weight: 400;
        color: #ADADAD;
        height: 45px;
        background: #eee;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        border: 1px solid #989898;
        padding: 0 15px;
        float: left;
        margin-left: 20px;
        cursor: pointer;
      }
      textarea{
        height: 310px;
        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        border: 1px solid #989898;
        padding: 5px 15px;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        box-sizing: border-box;
      }
      select{
        width: 25.714vw;
        height: 2.679vw;
        background: #FFFFFF;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        border: 1px solid #989898;
        padding: 0 15px;
        float: left;
      }
    }
    
    
  }
}
.LoginFooter{
  border-top:1px solid #BFBFBF;
  .FooterCenter{
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul{
      float: left;
      li{
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight{
      float: right;
      text-align: right;
      p{
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
.purchaseiFooter{
  padding: 0 !important;
  .purchaseiFooter-top{
    background-color: #1D1D24;
    padding: 60px 0;
    .footer_left{
      float: left;
      .footer_left_font{
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #808080;
        line-height: 28px;
        margin-top: 15px;
      }
    }
    .footer_right{
      float: right;
      .fl_div{
        float: left;
        margin-left: 55px;
        .fl_dev_title{
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #808080;
        }
      }
    }
    &::after{
      clear: both;
      display: block;
      content: '';
    }
  }
  .purchaseiFooter-bottom{
    background-color: #173D7C;
    height: 100px;
    line-height: 100px;
    color: #FFFFFF;
    &::after{
      clear: both;
      display: block;
      content: '';
    }
    .fl{
      float: left;
    }
    .fr{
      float: right;
    }
  }
  .w1200{
    margin: 0 auto;
    width: 1200px;
    &::after{
      clear: both;
      display: block;
      content: '';
    }
  }
  .fl_dev_font{
    margin-top: 20px;
    font-size: 16px;
    color: #808080;
    line-height: 30px;
    a:link,a:visited{
      color:#808080;
      text-decoration: none;
    }
    a:hover{
      color:#ffffff;
      text-decoration: none;
    }
  }
  .mt20{
    margin-top: 20px;
  }
  .mt30{
    margin-top: 30px;
  }
}

.showFile {
  background: rgba(67, 67, 67, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 0 50px;
  z-index: 999;
  .showFile-info {
    width: 520px;
    height: 225px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    margin-top: -210px;
  }
  .showFile-info i {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
  .showFile-info i img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  .showFile-info-font {
    text-align: center;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
    padding: 75px 30px 20px 30px;
  }
  .showFile-info-font span {
    color: #ff6600;
  }
  .showFile-info-button {
    width: 244px;
    height: 50px;
    background: #173d7c;
    border-radius: 6px;
    margin: 0 auto;
    line-height: 50px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
  }
  .showFile-info-button:hover {
    background: #10408f;
  }
  .showFile-info-h {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 20px;
    text-align: center;
  }
  .showFile-info-h span {
    border-bottom: 1px solid #666666;
    display: inline-block;
    cursor: pointer;
    line-height: 20px;
  }
}
</style>