<template>
  <div class="EduHome">
    <PurchasePageHeader />
    
    <main>
      <div class="page1-container" style="background: #F4F4F4;">
        <div class="w1200">
          <div class="rlineHeight">
            <span @click="$router.push({ path: '/PurchasePage'})">首页</span> / 
            <span>课程列表</span>
          </div>
          <div class="seveHeight">筛选：
            <span v-for="(item, index) in tags" :key="index+'type'" 
              :class="params.tags == item ? 'on':''" 
              @click="filterTypeFn(item)"
            >{{ item }}</span>
          </div>
          <div class="PurchasePage_ul_1">
            <ul>
              <li v-for="(item, index) in courseList" :key="index+'course'">
                <div class="PurchasePage_Item" @click="handleDetail(item)">
                  <div class="PurchasePage_Item_img">
                    <img v-if="item.image" :src="item.image" />
                    <img v-else src="../../assets/PurchasePage/timg.png" />
                  </div>
                  <div class="PurchasePage_Main">
                    <div class="PurchasePage_Mian_title" :title="item.name">{{ item.name }}</div>
                    <div class="PurchasePage_Mian_font" :title="item.summary">
                      <!-- <span v-html="item.description"></span> -->
                      {{ item.summary }}
                      <!-- 股票期权作为企业管理中的一种激励手段，最早起源于美国，在上世纪70~80年代走向成熟， -->
                    </div>
                    <div class="PurchasePage_Mian_money">
                      <div class="money_fl">{{ (item.price/100).toFixed(2) }} 元</div>
                      <div class="money_fr">
                        <img src="../../assets/PurchasePage/gwleft.png">
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>

    <footer class="purchaseiFooter">
      <div class="purchaseiFooter-top">
        <div class="w1200">
          <div class="footer_left">
            <div><img src="../../assets/footer_logo.png"></div>
            <div class="footer_left_font">华路数字文化<br>CPA沉浸式教学互动平台</div>
          </div>
          <div class="footer_right">
            <div class="fl_div">
              <div class="fl_dev_title">快捷服务</div>
              <div class="fl_dev_font">
                <p><a href="http://www.cufehl.com"  target="_balnk">关于我们</a></p>
                <p @click="$router.push({path: '/purchaseitemInfo'})"><a href="#">联系我们</a></p>
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">微信公众号</div>
              <div class="mt20">
                <img src="../../assets/footer_code.png" />
              </div>
            </div>
            <div class="fl_div">
              <div class="fl_div_title_1">客服/咨询</div>
              <div class="mt30" @click="$router.push({path: '/purchaseitemInfo'})">
                <img src="../../assets/title_1.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purchaseiFooter-bottom">
        <div class="w1200">
          <div class="fl">COPYRIGHT © 2009-2020 华路数字文化 版权所有</div>
          <div class="fr">粤ICP备14094585号</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { getProductTags, getProductBouque } from "@/api/product.js";
import PurchasePageHeader from "@/components/PurchasePageHeader";
export default {
  name: "purchaseMore",
  data() {
    return {
      params:{
        // __orderBy: '-price',
        __orderBy: "-featured",
        published: true, // 已发布
        __page: 0,
        __pageSize: 999,
        tags:''
      },
      tags: [],
      courseList: []
    };
  },
  components: {
    PurchasePageHeader
  },
  methods: {
    // 筛选课程
    filterTypeFn (val) {
      if (this.params.tags === val) {
        this.params.tags = ''
      } else {
        this.params.tags = val
      }
      this.getProductBouque()
    },
    // 查看课程详情
    handleDetail(row) {
      console.log('课程:', row)
      this.$router.push({
        path: "/purchaseShow",
        query: {
          id: row.id,
        }
      })
    },

    // 加入购物车
    addShopping (val) {
      console.log('加入购物车')
    },

    // 查看课程
    getProductBouque() {
      getProductBouque(this.params).then(
        (res) => {
          if (res.code == 20000) {
            this.courseList = res.data;
          } else {
            this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
          }
        }
      );
    },
    // 分类
    async categoryFn() {
      const { code, data } = await getProductTags();
      if (code == 20000) {
        this.tags = data;
        // this.tags.unshift({id: '', name: '全部'})
      } else {
        this.$baseMessage(data.msg, "error", "vab-hey-message-error");
      }
    },

  },

  created() {
    if (this.$route.query.tags) {
      this.params.tags = this.$route.query.tags
    }
    this.categoryFn()
    this.getProductBouque()
  },

};
</script>

<style lang="scss" scoped>
.page1-container {
  flex: 1;
  padding: 0px 0px 35px 0;
  .w1200{
    width: 1200px;
    margin: 0 auto;
  }
  .AskForMian{
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .AskForFromTable{
      margin: 0 -15px;
      border: 0;
      padding-bottom: 20px;
      td{
        padding: 0 15px;
        padding-bottom: 30px;
        box-sizing: border-box;
        width: 50%;
        .FontTitle{
          line-height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          
        }
        .FontInput{
          height: 45px;
          background: #FFFFFF;
          border: 1px solid #989898;
          
          border-radius: 3px;
          box-sizing:content-box;
          font-size: 0;
          input{
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 0 15px;
            height: 45px;
            font-size: 16px;
            border: 0;
            background: transparent;
          }
        }
        .FontTextarea{
          background: #FFFFFF;
          border: 1px solid #989898;
          
          border-radius: 3px;
          box-sizing:content-box;
          font-size: 0;
          textarea{
            width: 100%;
            box-sizing: border-box;
            border: 0;
            padding: 7px 15px;
            font-size: 16px;
            border: 0;
            background: transparent;
            line-height: 24px;
            font-family: 'Microsoft YaHei';
          }
        }
        ul{
          li{
            padding: 10px 0;
            line-height: 24px;
          }
        }
      }
    }
    .AskForButton{
      background: #173D7C;
      border-radius: 10px;
      cursor: pointer;
      border:0;
      font-size: 16px;
      color:#ffffff;
      width: 149px;
      height: 50px;
      
      &:hover{
        background: #244f96;
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter{
  border-top:1px solid #BFBFBF;
  .FooterCenter{
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul{
      float: left;
      li{
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight{
      float: right;
      text-align: right;
      p{
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}

.PurchasePage_ul_1{
  margin: 0 -6px;
  &::after{
    clear: both;
    display: block;
    content: '';
  }
  ul{
    li{
      float: left;
      width: 25%;
      box-sizing: border-box;
      padding: 0 6px;
      margin-top: 20px;
      .PurchasePage_Item{
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #979797;
        overflow: hidden;
        .PurchasePage_Item_img{
          height: 166px;
          img{
            width: 100%;
            // height: 100%;
          }
        }
        .PurchasePage_Main{
          padding: 0 25px;
          .PurchasePage_Mian_title{
            font-size: 24px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #171717;
            padding: 20px 0;
            line-height: 30px;
            overflow: hidden; // 溢出部分隐藏 
            white-space: nowrap; // 文字不换行
            text-overflow: ellipsis; // 显示省略号
          }
          .PurchasePage_Mian_font{
            height: 4.1vw;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #181818;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .PurchasePage_Mian_money{
            padding: 20px 0;
            &::after{
              clear: both;
              display: block;
              content: '';
            }
            .money_fl{
              float: left;
              font-size: 24px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #FF7700;
            }
            .money_fr{
              float: right;
            }
          }
        }
      }
    }
  }
}
.PurchasePage_InfoMain_right_a{
  padding-top: 35px;
  a:link,a:visited{
    text-align: center;
    display: inline-block;
    width: 134px;
    height: 35px;
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    line-height: 33px;
    border: 1px solid #173D7C;
    span{
      display: inline-block;
      color: #173D7C;
    }
    i{
      display: inline-block;
      background: url("../../assets/PurchasePage/aheader.png") no-repeat center;
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }
}
.PurchasePage_InfoMain{
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  padding: 35px;
  &::after{
    clear: both;
    display: block;
    content: '';
  }
  .PurchasePage_InfoMain_left{
    width: 455px;
    img{width: 100%;}
    float: left;
  }
  .PurchasePage_InfoMain_right{
    padding-left: 500px;
    .PurchasePage_InfoMain_right_title{
      font-size: 24px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #173D7C;
      padding: 20px 0;
    }
    .PurchasePage_InfoMain_right_font{
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #585A59;
      line-height: 28px;
    }
  }
}
.rlineHeight{
  padding-top:15px;
  line-height:50px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #6B6C6D;
  >span {
    cursor: pointer;
  }
}
.seveHeight{
  height: 60px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 0 20px;
  line-height: 60px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #6B6C6D;
  span,a:link,a:visited{
    margin-right: 20px;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    padding: 5px 10px;
    cursor: pointer;
  }
  .on,a:hover,span:hover{
    background: #DBF5FF;
  }
}
.purchaseiFooter{
  padding: 0 !important;
  .purchaseiFooter-top{
    background-color: #1D1D24;
    padding: 60px 0;
    .footer_left{
      float: left;
      .footer_left_font{
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #808080;
        line-height: 28px;
        margin-top: 15px;
      }
    }
    .footer_right{
      float: right;
      .fl_div{
        float: left;
        margin-left: 55px;
        .fl_dev_title{
          font-size: 24px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #808080;
        }
        .fl_div_title_1{

        }
      }
    }
    &::after{
      clear: both;
      display: block;
      content: '';
    }
  }
  .purchaseiFooter-bottom{
    background-color: #173D7C;
    height: 100px;
    line-height: 100px;
    color: #FFFFFF;
    &::after{
      clear: both;
      display: block;
      content: '';
    }
    .fl{
      float: left;
    }
    .fr{
      float: right;
    }
  }
  .w1200{
    margin: 0 auto;
    width: 1200px;
    &::after{
      clear: both;
      display: block;
      content: '';
    }
  }
  .fl_dev_font{
    margin-top: 20px;
    font-size: 16px;
    color: #808080;
    line-height: 30px;
    a:link,a:visited{
      color:#808080;
      text-decoration: none;
    }
    a:hover{
      color:#ffffff;
      text-decoration: none;
    }
  }
  .mt20{
    margin-top: 20px;
  }
  .mt30{
    margin-top: 30px;
  }
}
</style>