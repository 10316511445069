<template>
  <div class="AskForNav">
    <ul>
      <li @click="$router.push({path: '/PurchasePage'})">注册会计师课程</li>
      <!-- <li @click="$router.push({path: '/home'})">学习中心</li> -->
      <li><a href="http://www.cufehl.com" target="_balnk">关于我们</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AskForNav",
};
</script>

<style lang="scss" scoped>
.AskForNav {
  padding-top: 18px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  ul {
    display: inline-block;
    li {
      float: left;
      margin-left: 30px;
      cursor: pointer;
      a {
        color: #000000;
      }
    }
  }
}
</style>