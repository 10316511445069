<template>
  <div class="LoginItem">
    <ul>
      <li class="" @click="gotoHome">教学平台</li>
      <li v-if="isLogout" @click="login">登录/注册</li>
      <li v-if="!isLogout">
        <EduAvatar @setGoToUrl="setGoToUrl" :memberAvatar="memberAvatar"/>
      </li>
      <li v-if="!isLogout">
        <EduLogout @setGoToUrl="setGoToUrl" :imgColor="true" />
      </li>
    </ul>
  </div>
</template>

<script>
import EduAvatar from "../../EduHeader/components/EduAvatar.vue";
import EduLogout from "../../EduHeader/components/EduLogout.vue";

export default {
  name: "LoginItem",
  props: {
    memberAvatar: {
      type: String,
      default: "",
    },
  },
  data(){
    return {
      isLogout: this.$store.state.isLogout
    }
  },
  components: {
    EduAvatar,
    EduLogout,
  },
  methods: {
    setGoToUrl(_url) {
      this.$emit("setGoToUrl", _url);
    },

    gotoHome (){
      if (this.isLogout) {
        this.$router.push({
          path: "/Login",
          query: { redirect: this.$route.path },
          replace: true,
        })
      } else {
        this.$router.push({path: '/home'})
      }
    },

    login () {
      console.log('登录', )

      this.$router.push({
        path: "/Login",
        query: { redirect: this.$route.path },
        replace: true,
      })
    }
  },

  created() {

  }
};
</script>

<style lang="scss" scoped>
.LoginItem {
  position: absolute;
  right: 20px;
  top: 36px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  ul {
    li {
      line-height: 35px;
      float: left;
      margin-left: 30px;
      cursor: pointer;
      position: relative;
      &:hover{
        .LoginNav{
          display: block;
        }
      }
      .LoginNav{
        position: absolute;
        right: 0;
        width: 242px;
        background: #FFFFFF;
        border: 1px solid #D4D4D4;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        
        border-radius: 6px;
        padding: 10px 25px;
        display: none;
        dd{
          padding: 10px 0;
        }
        .LoginNavTitle{
          color:#173D7C;
          line-height: 32px;
        }
        .LoginNavFont{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #191917;
          
        }
      }
    }
  }
}
</style>