<template>
  <header class="AskForLogoCenter">
      <div class="header-center">
        <AskForLogo />
        <AskForNav />
      </div>
      <LoginItem />
  </header>
</template>

<script>
import AskForLogo from "./components/AskForLogo";
import AskForNav from "./components/AskForNav";
import LoginItem from "./components/LoginItem";
export default {
  name: "AskForHeader",
  components: {
    AskForLogo,
    AskForNav,
    LoginItem
  },
};
</script>

<style lang="scss" scoped>
.AskForLogoCenter {
  height: 106px;
  background: #FFFFFF;
  border-top: 6px solid #173D7C;
  .header-center{
    width: 1200px;
    margin: 0 auto;
    line-height: 60px;
  }
}
</style>
